@import "reset";

$fonts: 'Noto Sans', sans-serif;
$bg-color: #17181e;
$default-font-size-px: 16;
$vw: 2560;
$vh: 1440;
$ratio: 2;

@function fs($n) {
  @return ($n / $ratio / $default-font-size-px)+0rem;
}

@function rw($n, $w: $vw) {
  @return ($n / $w * 100)+0%;
}

@function rh($n, $h: $vh) {
  @return ($n / $h * 100)+0%;
}

@mixin placeholder($z-index: 10, $is-image: true) {
  z-index: $z-index;
  position: absolute;
  display: block;
  @if $is-image==true {
    text-indent: -5000px;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
@mixin placeholder2($z-index: 10, $is-image: true) {
  z-index: $z-index;
  display: block;
  @if $is-image==true {
    text-indent: -5000px;
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}
@mixin pxrel($left, $top, $width, $height, $image: null, $bg-size: null) {
  @include placeholder2;
  width:$width+px;
  @if $width == 0{
    width: 100%;
  }
  height: $height+px;
  left: $left+px;
  margin-top: $top+px;
  @if $image {
    background-image: url($image);
  }
  @if $bg-size {
    background-size: $bg-size;
  }
}
@mixin rel($left, $top, $width, $height, $parent-width: $vw, $parent-height: $vh, $image: null, $bg-size: null) {
  @include placeholder;
  width: $width+px;
  height: $height+px;
  left:  $left+px;
  top:  $top+px;
  @if $image {
    background-image: url($image);
  }
  @if $bg-size {
    background-size: $bg-size;
  }
}
@mixin abtn {display: block;overflow: hidden;position: absolute;z-index:1;text-indent: -9999px;}
@mixin txt {text-align:center;position:absolute;z-index:1;display:inline-block;}
@mixin number-images($from, $to, $prefix: 'n', $path: '../img', $ext: 'png') {
  @for $i from $from through $to {
    .#{$prefix}#{$i} {
      background-image: url(#{$path}/#{$prefix}#{$i}.#{$ext});
    }
  }
}
@mixin bg-height {position:relative;z-index:1;background-size:100% auto; background-repeat:no-repeat;height:0;padding-top:($vh / $vw) * 100%;}
@mixin bg-height2 {position:relative;z-index:1;background-size:100% auto; background-repeat:no-repeat;height:0;padding-top:($vh2 / $vw) * 100%;}
@mixin image-popup($z-index: 500) {
  z-index: $z-index;
  box-sizing: border-box;
  position: absolute;
  background: {
    repeat: no-repeat;
    size: 100%;
  }
  & .bg {
    display: block;
    width: 100%;
  }
  & .msgbox {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: $z-index + 100;
  }
}
@mixin breakpoint($point) {
  @if $point==xxs {
      @media (max-width: 320px) {
          @content;
      }
  }
  @else if $point==xs {
      @media (max-width: 767px) {
          @content;
      }
  }
  @else if $point==sm {
      @media (min-width: 768px) and (max-width: 991px) {
          @content;
      }
  }
  @else if $point==md {
      @media (min-width: 768px) and (max-width: 1199px) {
          @content;
      }
  }
  // @else if $point==md-d {
  //     @media (min-width: 1200px) and (max-width: 1299px) {
  //         @content;
  //     }
  // } //md-d is of Medium Desktop
  @else if $point==lg {
      @media (max-width: 1200px) {
          @content;
      }
  }
  @else if $point==landscape {
    @media (orientation:landscape) {
        @content;
    }
  }
  @else {
      @warn "Breakpoint mixin supports: xxs, xs, sm, md, lg";
  }
}

.test {
  position: fixed;
  left: 5px;
  top: 5px;
  z-index: 5000;
}

select.test {
  width: auto;
  font-size: 14px;
}

@import "main";
@import "popup";