@-webkit-keyframes aniPopup {
  0% { -webkit-transform: scale(0.6); }
  60% { -webkit-transform: scale(1.1); }
  100% { -webkit-transform: scale(1); }
}
@keyframes aniPopup {
  0% {transform: scale(0.6); }
  60% {transform: scale(1.1); }
  100% {transform: scale(1); }
}
#modalShadow {display: none;position: fixed;left: 0;top: 0;z-index: 20;width: 100%;height: 100%;background-color: #000;
  opacity: 0.6;}
.popup{background:url('../img/bg_layer.png') no-repeat center top / 100% auto;box-sizing:border-box; position:fixed; z-index:500; left:50%; top:50%;width:638px;height:414px;text-align:center;line-height: 1.4;display:table;transform:translate(-50%,-50%);
  h2 {display:block; position:relative; z-index:10; height:42px; margin-bottom:20px; background-color:#112c47; color:white; font-weight:bold; font-size:18px; line-height:2.4; text-align:center;}
  .btn{position:absolute; left:0; bottom:12%; width:100%; height:20.9777%;
    a:hover{text-decoration:none;}
    a {position:relative;display:inline-block; width:42%; height:100%; margin:0 2px; background:no-repeat url(../img/blank.gif); color:#FFF; font-weight:bold; font-size:14px; line-height:2.1; text-align:center; background-size:100%;z-index:20;text-indent:-9999px;}
  }
  .msgbox{display:table-cell;vertical-align: middle;}
  .btn_cancel{opacity:0.85;}
  .btn_confirm{position: absolute;z-index: 20;text-indent: -5000px;left: 0;top: 0;width: 100%;height:100%;background-repeat:no-repeat;background-position:center 90%}
  .desc {display:block;position:absolute;left:5%;width:90%;top:0; height:auto; margin:0 auto; padding:0; text-align:center; letter-spacing:-1px;z-index:20;line-height: 1.5em;color:#262626;height:70%;display: flex;justify-content: center;align-items: center;font-size:1em;
  }
}
.popup{
  .btn {bottom:8.9%;height:rh(129,538);
    a {width:37%;}
  }
}