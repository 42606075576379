
body {
  font-family: $fonts;background-color:$bg-color;overflow-x:hidden;
}

html {
  font-size: $default-font-size-px + 0px;
}

input::placeholder{color:#909090}
.wrap_coupon ::-webkit-scrollbar {
  width: 8px;
  background-color: black;
}
.wrap_coupon ::-webkit-scrollbar-thumb {
  background-color: #2f3542;
}
.wrap_coupon {
  position: relative;width: 100%;color:#909090;letter-spacing: -1px;font-size:30px;background:url('../img/bg.jpg') no-repeat center top / auto 100%;min-width:700px;padding-bottom:7%;
  .header {padding-top:24px;
    .logo{@include pxrel(0,0,0,364);margin:0 auto 4px auto;max-width:726px;;height:0;padding-top:(364 / 726) * 100%;background-size:100% auto}
  }
  .bg {
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
  .wrap_in{margin:0 auto;text-align:center;max-width: 706px;padding:0 25px;}
  .content{margin:0 auto;
    .select, .name, .coupon{border:1px solid #87a1a0;border-radius:10px;background:#243530;display:block;width:100%;box-sizing:border-box;color:#909090;text-indent:20px;height:98px;margin-top:10px;}
    // .select{color:#909090;text-indent:20px;background-image:url('../img/ic_arrow.png');background-repeat:no-repeat;background-position:95% 50%;background-size:auto 27px;
      // option{text-align:center;}
    // }
  }
  .btn_coupon{@include pxrel(0,16,0,152);margin:40px auto 3% auto;max-width:574px;background-position:center top; }
  .off, .ok{pointer-events: none;cursor: default ;}
  .warning{text-align:left;background:rgba(14,17,27,0.7);padding:5% 3% 5% 5%;border-radius:10px;box-sizing:border-box;position:relative;font-size:25px;height:448px;
    .warning_in{padding-right:2%;
      line-height:1.4em;overflow-y:scroll;height:100%;
    }
    &:after{content:'';display:block;position: absolute;;bottom:0;left:0;width:100%;height:80px;background:url(../img/shadow.png) repeat-x;background-size:auto;border-radius:10px;}
    h2{color:#ffeea1;text-align:center;font-weight:bold;margin-bottom: 5%;}
    ol{padding-left:3%;}
    li{margin-bottom:2%;text-indent:-3%;}
  }
  .eff{
    i{display:block;}
    .eff_1{@include rel(0,482,488,446,$image:'../img/eff_1.png');left:50%;margin-left:-766px;animation:eff1 ease-in-out 0.8s;-webkit-animation:eff1 ease-in-out 0.8s }
    .eff_2{@include rel(0,318,150,162,$image:'../img/eff_2.png');left:50%;margin-left:344px;;animation:eff2 ease-out 0.6s 0.1s forwards;-webkit-animation:eff2 ease-out 0.6s 0.1s forwards;opacity:0}
    .eff_3{@include rel(0,477,101,106,$image:'../img/eff_3.png');left:50%;margin-left:423px;;animation:eff2 ease-in 0.7s 0.3s forwards;-webkit-animation:eff2 ease-in 0.7s 0.3s forwards;opacity:0}
  }
}
@keyframes eff1 {
  0%{transform: translateX(-100%);opacity:0}
  100%{transform: translateX(0%);opacity:1}
  
}

@keyframes eff2 {
  0%{transform: translateY(-250%);opacity:0}
  70%{transform: translateY(0%);opacity:1}
  75%{transform: rotateY(90deg)}
  100%{transform:rotateY(0deg);opacity:1}
  
}
@-webkit-keyframes eff1 {
  0%{transform: translateX(-100%);opacity:0}
  100%{transform: translateX(0%);opacity:1}
  
}

@-webkit-keyframes eff2 {
  0%{transform: translateY(-250%);opacity:0}
  70%{transform: translateY(0%);opacity:1}
  75%{transform: rotateY(90deg)}
  100%{transform:rotateY(0deg);opacity:1}
  
}
.wrap_coupon.mobile{
  
  @include breakpoint(xxs){height:auto !important;padding-bottom:10%;}
  @include breakpoint(xs){min-width:auto;height:100vh;font-size:1.3em;
    .wrap_in{max-width:320px;margin:0 auto;padding:0 12px;
      .name, .coupon{height:50px;margin-top:7px;}
      .btn_coupon{@include pxrel(0,25,0,76)}
      .warning{font-size:0.9em;height:200px;
        &:after{height:40px}
      }
      .eff{display:none}
    }
    .popup{width:319px;height:207px;
      .btn_confirm{background-size:145px auto}
    }
  }
  @include breakpoint(landscape){font-size:0.7em;height:100%;
    .wrap_in{max-width:340px;}
  }
  

}

$langSet:'en', 'ja', 'tw', 'ko';
@each $lang in $langSet{
  html[lang=#{$lang}]{
    .wrap_coupon{
      .bg{background-image:url('../img/bg.jpg');}
      .logo{background-image:url('../img/#{$lang}/logo.png')}
      .btn_coupon{background-image:url('../img/#{$lang}/btn_reg--on.png')}
      .btn_confirm{background-image:url('../img/#{$lang}/btn_ok.png')}
    }
  }
}